import React from 'react';
import styled from 'styled-components';

import {Container} from 'react-bootstrap';
import ItemContainer from './containers/ItemContainer';
import ClientCard from './ClientCard';
import leftQuote from '../assets/icons/left-quote.png';

const TextBubbleContainer = styled(Container)`
  position: relative;
`

const TextBubble = styled(ItemContainer)`
  border-radius: ${props => props.theme.padding.item.standard};
  box-shadow: ${props => props.theme.shadow.standard};
  background-color: white;
  
  & > * {
    text-align: center;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 15px solid;
    border-color: transparent;
    border-top-color: white;
    margin-top: ${props => props.theme.padding.item.standard};
    left: 50%;
    margin-left: -15px;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const QuoteImage = styled.img`
  top: 0px;
  width: 50px;
  height: 50px;
  opacity: 0.3;
  margin-bottom: -35px;
`

export default function VerticalTestimonial({client, title, content}) {
  return (
    <TextBubbleContainer>
      <TextBubble>
        <QuoteImage src={leftQuote} alt="Quotation Mark" />
        <h4>{title}</h4>
        <p>{content}</p>
      </TextBubble>
      <br/>
      <BottomWrapper>
        <ClientCard client={client} />
      </BottomWrapper>
    </TextBubbleContainer>
  )
}
