import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from '../components/Layout';
import SectionBlock from '../components/containers/SectionBlock';
import {Col, Container, Row} from 'react-bootstrap';
import {faUniversity} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styled from 'styled-components';
import {ButtonLink} from '../components/Button';

import mask from '../assets/banners/bgmask.jpg';

import emailjs from "emailjs-com";
import VerticalTestimonial from '../components/VerticalTestimonial';
import StatisticCard from '../components/StatisticCard';
import useHomePage from '../hooks/useHomePage';
emailjs.init("user_h4CoeHmqLpDG8TjqeAgff");

const ContentCircle = styled.div`
  background: ${props => props.theme.colors.lightBlue};
  border-radius: 50%;
  width: 100%;
  padding: 10px;
`

const BackgroundColorBlockWrapper = styled.div`
  background: ${props => props.half ? 'linear-gradient(0deg, white 50%, ' + props.theme.colors.blue + ' 50%)' : props.theme.colors.blue};
`

function BackgroundColorBlock({children, half}) {
  return (
    <BackgroundColorBlockWrapper half={half}>
      <SectionBlock>
        {children}
      </SectionBlock>
    </BackgroundColorBlockWrapper>
  )
}

const BackgroundImageBlock = styled(SectionBlock)`
  background-image: url(${props => props.image});
  background-size: cover;
`

const WelcomeImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  display: block;
  width: 80%;
  aspect-ratio: 1;
  margin-top: 12%;
  border-radius: ${props => props.theme.padding.item.standard};
`

function Index({location}) {

  const {
    welcomeMessage,
    clientTestimonials,
    statistics,
    welcomeImage
  } = useHomePage();

  return (
    <Layout location={location}>
      <SectionBlock>
        <Row style={{alignItems: 'center'}}>
          <Col md={6}>
            <ContentCircle style={{height: 0, paddingBottom: '75%', width: '75%', display: 'inline-block'}}>
              <WelcomeImage src={welcomeImage.file.url} alt={welcomeImage.title} />
            </ContentCircle>
          </Col>
          <Col md={6}>
            <h2>
              Welcome
            </h2>
            <p>
              {welcomeMessage.welcomeMessage}
            </p>
            <br/>
            <ButtonLink to={'/about/'} secondary>
              Learn More
            </ButtonLink>
          </Col>
        </Row>
      </SectionBlock>

      <BackgroundColorBlock>
        <h2 className={'white-text'}>Our clients speak</h2>
        <br/>
        <Row className={'gy-4'}>
          {
            clientTestimonials.map((node) =>
              <Col md={6}>
                <VerticalTestimonial client={{image: node.logo.file.url, name: node.company}} title={node.title} content={node.testimony.testimony}/>
              </Col>
            )
          }
        </Row>
      </BackgroundColorBlock>

      <BackgroundColorBlock half>
        <h2 className={'white-text'}>
          About our branch...
        </h2>
        <p className={'white-text'}>
          Our achievements by the numbers.
        </p>
        <Row className={'gy-4'} md={4} sm={2} xs={2}>
          {
            statistics.map((node) =>
              <Col>
                <StatisticCard title={node.quantity} subtitle={node.description} />
              </Col>
            )
          }
        </Row>
      </BackgroundColorBlock>

      <SectionBlock className={'text-center'}>
        <h2 className={'text-center'}>Request to start a new consulting project with us</h2>
        <br/>
        <br/>
        <ButtonLink to={'/contact/'} large>Get started</ButtonLink>
      </SectionBlock>

      <SectionBlock>
        <BackgroundImageBlock image={mask} className={'white-text'} style={{borderRadius: '30px'}}>
          <Row className={'gy-4'}>
            <Col md={4} align={'center'}>
              <FontAwesomeIcon icon={faUniversity} color={'white'} size={'8x'} />
            </Col>
            <Col md={8}>
              <Container>
                <h2 className={'text-white'}>...or are you a student looking to join?</h2>
                <br/>
                <ButtonLink to={'/join/'} secondary style={{borderColor: 'white', color: 'white'}} large>Join us</ButtonLink>
              </Container>
            </Col>
          </Row>
        </BackgroundImageBlock>
      </SectionBlock>
    </Layout>
  );
}

export default Index;