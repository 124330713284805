import React from 'react';
import styled from 'styled-components';
import ItemContainer from "./containers/ItemContainer";

const StatWrapper = styled(ItemContainer)`
  background-color: white;
  box-shadow: ${props => props.theme.shadow.standard};
  padding-top: ${props => props.theme.padding.item.huge};
  padding-bottom: ${props => props.theme.padding.item.huge};
  border-radius: ${props => props.theme.padding.item.standard};
  color: black;
  
  & > * {
    text-align: center;
  }
`

export default function StatisticCard({title, subtitle}) {
  return (
    <StatWrapper>
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </StatWrapper>
  )
}