import {graphql, useStaticQuery} from 'gatsby';

const homePageQuery = graphql`
  query {
    allContentfulHomePage(sort: {fields: updatedAt, order: DESC}) {
      nodes {
        subheader
        welcomeImage {
          title
          file {
            url
          }
        }
        welcomeMessage {
          welcomeMessage
        }
        clientTestimonials {
          testimony {
            testimony
          }
          logo {
            file {
              url
            }
          }
          company
          title
        }
        statistics {
          quantity
          description
        }
      }
    }
  }
`

export default function useHomePage() {
  const homePageLatestNode = useStaticQuery(homePageQuery).allContentfulHomePage.nodes[0];
  return homePageLatestNode;
}