import React from 'react';
import styled from 'styled-components';

const MemberImage = styled.img`
  height: 100px;
`

const ClientCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
`

const NameText = styled.h6`
  text-align: center;
`

export default function ClientCard({client}) {
  return (
    <ClientCardWrapper>
      <MemberImage src={client.image} alt={client.name}/>
      <NameText>{client.name}</NameText>
    </ClientCardWrapper>
  )
}